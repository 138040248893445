<template>
    <div id="login-page">
        <div id="billboard">
            <div class="logo-wrapper">
                <img :src="getLogoUrl()" class="img-fluid" alt="" />
            </div>
            <div class="baseline-wrapper">
                <img :src="getBaselineUrl()" class="img-fluid" alt="" />
            </div>
        </div>
        <b-container id="login-form" class="text-center">
            <b-form novalidate class="mt-1 mb-3 w-100" @submit="login">
                <b-form-row class="justify-content-center">
                    <b-col sm="6" lg="5" xl="4">
                        <b-form-group>
                            <b-list-group>
                                <b-list-group-item>
                                    <b-form-input
                                        class="form-control-lg border-0"
                                        autocomplete="off"
                                        type="text"
                                        placeholder="Votre identifiant"
                                        v-model="form.login"
                                        :state="formValidation.login"
                                        aria-invalid="login-error"
                                        :required="true"
                                    />
                                    <b-form-invalid-feedback id="login-error">
                                        Veuillez saisir le login
                                    </b-form-invalid-feedback>
                                </b-list-group-item>
                                <b-list-group-item>
                                    <b-input-group class="mt-3">
                                        <b-form-input
                                            class="form-control-lg border-0"
                                            :type="passwordFieldType"
                                            autocomplete="off"
                                            placeholder="Mot de passe"
                                            v-model="form.password"
                                            :state="formValidation.password"
                                            aria-invalid="pwd-error"
                                            :required="true"
                                        >
                                        </b-form-input>

                                        <b-input-group-append>
                                            <b-input-group-text
                                                @click="switchVisibility"
                                            >
                                                <i class="fa fa-eye"></i>
                                            </b-input-group-text>
                                        </b-input-group-append>

                                        <b-form-invalid-feedback id="pwd-error">
                                            Veuillez saisir le mot de passe
                                        </b-form-invalid-feedback>
                                    </b-input-group>
                                </b-list-group-item>

                                <div
                                    id="rememberMe"
                                    class="text-white text-left mt-3"
                                >
                                    <b-form-checkbox v-model="rememberMe">
                                        Mémoriser mon identifiant
                                    </b-form-checkbox>
                                </div>
                            </b-list-group>
                        </b-form-group>
                        <b-button
                            variant="danger"
                            size="lg"
                            class="align-items-center d-flex justify-content-center w-100"
                            type="submit"
                        >
                            <span class="align-items-center d-flex"
                                ><i class="fa fa-sign-in-alt fas mr-3"></i> Se
                                connecter</span
                            >
                        </b-button>
                    </b-col>
                </b-form-row>
            </b-form>
            <router-link class="text-white m-2" to="/forgotten-password">
                Identifiant ou mot de passe oublié
            </router-link>
            <router-link class="text-white mt-1" to="/register">
                Créer un compte</router-link
            >
            <b-row class="text-white mt-5"
                >version {{ require("../../../package.json").version }}</b-row
            >
        </b-container>
    </div>
</template>

<script>
import router from "../../router";
import userService from "../../services/userService";
import store from "../../store";

export default {
    name: "login",
    data() {
        return {
            form: {
                login: "",
                password: "",
            },
            formValidation: {
                login: null,
                password: null,
            },
            rememberMe: false,
            passwordFieldType: "password",
        };
    },
    mounted() {
        if (localStorage.getItem("rememberMe")) {
            this.rememberMe = true;
            this.form.login = localStorage.getItem("rememberMe");
        }
    },
    methods: {
        switchVisibility() {
            this.passwordFieldType =
                this.passwordFieldType === "password" ? "text" : "password";
        },
        validateLogin() {
            this.formValidation.login = this.form.login.length > 0;
            return this.form.login.length > 0;
        },
        validatePassword() {
            this.formValidation.password = this.form.password.length > 0;
            return this.form.password.length > 0;
        },
        getLogoUrl() {
            if (!location.host.includes("family")) {
                return "/img/logo.png";
            } else {
                return "/img/gfs_logo_2.png";
            }
        },
        getBaselineUrl() {
            if (!location.host.includes("family")) {
                return "/img/baseline.png";
            } else {
                return "/img/gfs_baseline.png";
            }
        },
        login(event) {
            event.preventDefault();
            if (
                this.validateLogin() === true &&
                this.validatePassword() === true
            ) {
                this.$auth
                    .login(this.form.login, this.form.password, "home")
                    .then((response) => {
                        if (this.rememberMe) {
                            localStorage.setItem("rememberMe", this.form.login);
                        } else {
                            if (localStorage.getItem("rememberMe")) {
                                localStorage.removeItem("rememberMe");
                            }
                        }
                        this.$auth._storeToken(response);
                        this.$auth.rememberMe(
                            this.form.login,
                            this.form.password
                        );
                        return userService.getUser();
                    })
                    .then((responseUser) => {
                                store.commit("UPDATE_USER", responseUser.data);if (responseUser.data.login == 'admin') {
                    // Si le login est admin, on ne vérifie pas le contrat et on passe directement à la connexion
                    return true;
                } else {
                                return userService.hasValideContract(responseUser.data.idutilisateur);}
                            })
                    .then((hasValideContract) => {
                        if (hasValideContract) {
                                this.$emit("login");
                                router.push({ name: "home" });
                        } else {
                            throw new Error("Aucun contrat actif trouvé");
                        }
                    })
                    .catch((error) => {
                        if (error.message === "Aucun contrat actif trouvé") {
                    this.$bvToast.toast(
                        "Vous n'avez pas de contrat actif. Veuillez contacter votre responsable.",
                        {
                            title: "Accès refusé",
                            autoHideDelay: 5000,
                            variant: "warning",
                        }
                    );
                } else if
                                (this.$rollbar.error("Erreur 3007", error)) {
                                this.$bvToast.toast(
                                    "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.",
                                    {
                                        title: "Erreur 3007",
                                        autoHideDelay: 5000,
                                        variant: "danger",
                                    }
                                );
                            }
                        else if (error.message == "Network Error") {
                            this.$bvToast.toast(
                                "Le réseau semble rencontrer des difficultés",
                                {
                                    title: "Échec de la connexion internet",
                                    autoHideDelay: 5000,
                                    variant: "danger",
                                }
                            );
                        } else {
                            this.$rollbar.info("Login error", {
                                label: "Login error",
                                username: this.form.login,
                                useragent: navigator.userAgent,
                            });
                            this.$bvToast.toast(
                                "Identifiant ou mot de passe incorrect.",
                                {
                                    title: "Erreur d'authentification",
                                    autoHideDelay: 5000,
                                    variant: "danger",
                                }
                            );
                        }
                        console.error(error.message);
                    });
            }
        },
    },
};
</script>

<style scoped>
#login-page #billboard {
    position: absolute;
    width: 100%;
    left: 0;
}

#login-page #login-form {
    margin-top: 14em;
}

@media (min-width: 992px) {
    #login-page #login-form {
        margin-top: 18em;
    }
}
</style>
